import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';

const Footer = () => {
    const [footerData, setFooterData] = useState(null);
    const [iconUrls, setIconUrls] = useState({});
    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        email: '',
        empresa: '',
        whatsapp: '',
        mensaje: ''
    });

    const [status, setStatus] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://interprika.com/cms/wp-json/custom/v1/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setStatus('Correo enviado con éxito!');
                setFormData({ nombre: '', apellido: '', email: '', empresa: '', whatsapp: '', mensaje: '' });
            } else {
                setStatus('Error al enviar el correo.');
            }
        } catch (error) {
            console.error('Error:', error);
            setStatus('Error al conectar con el servidor.');
        }
    };

    useEffect(() => {
        // Inicia WOW.js para animaciones
        new WOW.WOW({
            live: false, // Si deseas detectar contenido cargado dinámicamente, cámbialo a true
        }).init();

        // Llama a la API de la página con ID 428
        fetch('https://interprika.com/cms/wp-json/wp/v2/pages/428')
            .then((response) => response.json())
            .then((data) => {
                setFooterData(data.acf); // Guarda los datos de los campos ACF
                fetchIcons(data.acf.redes_sociales); // Llama a la función para obtener las URLs de los íconos
            })
            .catch((error) => console.error('Error fetching footer data:', error));
    }, []);

    // Función para obtener las URLs de los íconos mediante sus IDs
    const fetchIcons = (socialMedia) => {
        socialMedia.forEach((social) => {
            fetch(`https://interprika.com/cms/wp-json/wp/v2/media/${social.icono}`)
                .then((response) => response.json())
                .then((data) => {
                    setIconUrls((prevState) => ({
                        ...prevState,
                        [social.icono]: data.source_url, // Almacena la URL del ícono con el ID como clave
                    }));
                })
                .catch((error) => console.error('Error fetching icon:', error));
        });
    };

    if (!footerData) {
        return <p>Loading footer...</p>;
    }

    return (
        <footer className="bg-black text-white max-md:pb-8 py-14 relative overflow-hidden">
            <div className="w-[1280px] 2xl:w-[1440px] max-w-full px-[20px] md:px-[30px] pt-[30px] lg:pt-[100px] lg:px-[100px] mx-auto flex max-md:flex-col-reverse justify-between">
                <div className="flex flex-col justify-between">
                    <div className="max-md:pb-5 wow animate__animated animate__fadeInLeft" data-wow-duration="2s">
                        <a href="./">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/icons/logo.svg`}
                                alt="Interprika Logo"
                                className="max-md:mt-4 mb-6"
                            />
                        </a>
                        <ul className="flex flex-col gap-3">
                            {footerData.enlaces_del_footer.map((enlace, index) => (
                                <li key={index} className="font-extralight">
                                    <a href={enlace.link}>{enlace.texto}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="flex flex-col gap-3 wow animate__animated animate__fadeInLeft" data-wow-duration="2s">
                        <div className="flex space-x-4">
                            {footerData.redes_sociales.map((red, index) => (
                                <a key={index} href={red.link} target="_blank" rel="noopener noreferrer">
                                    {iconUrls[red.icono] ? (
                                        <img
                                            src={iconUrls[red.icono]}
                                            alt="Red Social"
                                            className="h-6 w-6"
                                        />
                                    ) : (
                                        <p>Loading icon...</p> // Muestra un mensaje mientras carga el icono
                                    )}
                                </a>
                            ))}
                        </div>
                        <div className="text-sm flex max-md:flex-col gap-4">
                            <a href={footerData.numero.link}>{footerData.numero.texto}</a>
                            <a href={`mailto:${footerData.correo}`}>{footerData.correo}</a>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-end space-y-4 wow animate__animated animate__fadeInRight" data-wow-duration="2s">
                    <h2 className="text-4xl w-full font-medium mb-4">ESCRÍBENOS</h2>
                    <form onSubmit={handleSubmit} className="w-full flex flex-col space-y-4">
                        <div className="flex max-lg:flex-col max-lg:gap-4 lg:space-x-4">
                            <input
                                type="text"
                                name="nombre"
                                value={formData.nombre}
                                onChange={handleChange}
                                placeholder="NOMBRE"
                                className="bg-[#383838] p-2 w-full"
                                required
                            />
                            <input
                                type="text"
                                name="apellido"
                                value={formData.apellido}
                                onChange={handleChange}
                                placeholder="APELLIDO"
                                className="bg-[#383838] p-2 w-full"
                                required
                            />
                        </div>
                        <div className="flex max-lg:flex-col max-lg:gap-4 lg:space-x-4">
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="EMAIL"
                                className="bg-[#383838] p-2 w-full"
                                required
                            />
                            <input
                                type="text"
                                name="empresa"
                                value={formData.empresa}
                                onChange={handleChange}
                                placeholder="EMPRESA / PROYECTO"
                                className="bg-[#383838] p-2 w-full"
                            />
                        </div>
                        <input
                            type="text"
                            name="whatsapp"
                            value={formData.whatsapp}
                            onChange={handleChange}
                            placeholder="WHATSAPP"
                            className="bg-[#383838] p-2 w-full"
                        />
                        <textarea
                            name="mensaje"
                            value={formData.mensaje}
                            onChange={handleChange}
                            placeholder="MENSAJE"
                            className="bg-[#383838] p-2 w-full h-24"
                            required
                        />
                        <button type="submit" className="py-2 px-4 mb-12 md:my-4 flex items-center gap-3 bg-white text-black w-[200px]">
                            ENVIAR <img src={`${process.env.PUBLIC_URL}/images/icons/arrow-right.svg`} alt="Arrow" className="h-4 w-4" />
                        </button>
                    </form>
                    {status && <p>{status}</p>}
                </div>
            </div>
            <div className="clip-diagonal3"></div>
            <div className="clip-diagonal4"></div>
        </footer>
    );
};

export default Footer;