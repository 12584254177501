import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import WOW from 'wowjs';
import 'animate.css';

const PostDetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);

  const fetchPost = async () => {
    try {
      // Fetch detalles del post usando el id desde la API de WordPress
      const response = await fetch(`https://interprika.com/cms/wp-json/wp/v2/posts/${id}?_embed`);
      const postData = await response.json();
      setPost(postData);

      // Fetch de los posts más recientes excluyendo el actual
      const recentPostsResponse = await fetch('https://interprika.com/cms/wp-json/wp/v2/posts?_embed');
      const recentData = await recentPostsResponse.json();
      
      // Filtra el post seleccionado y toma los más recientes excluyendo el actual
      const filteredPosts = recentData.filter(post => post.id !== parseInt(id)).slice(0, 4);
      setRecentPosts(filteredPosts);
      
    } catch (error) {
      console.error('Error fetching post:', error);
    }
  };

  useEffect(() => {
    // Inicia WOW.js para animaciones
    new WOW.WOW({
      live: false, // Si deseas detectar contenido cargado dinámicamente, cámbialo a true
    }).init();
  }, []);

  useEffect(() => {
    fetchPost();
  }, [id]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className='w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto'>
        <div className='flex flex-col lg:flex-row relative items-center'>
          <div className="w-full py-5 pr-5 mt-6 mb-20 wow animate__animated animate__fadeInLeft" data-wow-duration="2s">
            {/* Imagen destacada */}
            <div className="w-full h-[400px] bg-gray-300 mb-4 wow animate__animated animate__fadeInDown" data-wow-duration="2s">
              {post._embedded['wp:featuredmedia'] && (
                <img 
                  src={post._embedded['wp:featuredmedia'][0].source_url} 
                  alt={post.title.rendered} 
                  className="object-cover w-full h-full" 
                />
              )}
            </div>

            {/* Fecha de publicación y tiempo estimado de lectura */}
            <div className="flex justify-between items-center text-gray-500 mb-4">
              <span className='wow animate__animated animate__fadeInLeft' data-wow-duration="2s">
                {new Date(post.date).toLocaleDateString()}
              </span>
              <span className='wow animate__animated animate__fadeInRight' data-wow-duration="2s">
                {Math.ceil(post.content.rendered.split(' ').length / 200)} min de lectura
              </span>
            </div>
          </div>
        </div>
        
        <div className="flex flex-col lg:flex-row relative">
          <div className="w-full lg:w-3/4 pb-5 border-r">
            {/* Título y contenido del post */}
            <h1 className="text-3xl font-bold mt-[-70px] mb-10">{post.title.rendered}</h1>
            <div className='[&_*]:max-w-full [&_img]:max-w-full' dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
          </div>

          <div className="w-full lg:w-1/4 pt-4 md:p-4 sticky md:top-[140px] h-[400px] wow animate__animated animate__fadeInRight" data-wow-duration="2s">
            <h3 className="md:mt-[-80px] mb-12 text-right bg-black text-white p-1 px-3">Últimos <span className='font-bold'>Artículos</span></h3>
            {recentPosts.map(post => (
                <div key={post.id} className="mb-4">
                    <Link to={`/blog/${post.id}`} className='flex items-center' >
                        <div className="flex-shrink-0 w-[60px] h-[60px] bg-gray-300 rounded"></div>
                        <div className="ml-4">
                        <h4 className="text-sm font-medium uppercase">
                            {post.title.rendered}
                        </h4>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
        </div>
      </div> 
    </div>
  );
};

export default PostDetail;
